import { DefaultSeoProps } from 'next-seo';

const title = 'Spelvärde';
const description =
  'Spelvärde.se grundades i mars 2014 med ambitionen att erbjuda världens mest genomarbetade och bästa travtips. Våra starka resultat har medfört att vi på rekordtid blivit Sveriges ledande tipstjänst för spel på trav.';

export const TITLE_SEPARATOR = ' · ';

const defaultConfig: DefaultSeoProps = {
  titleTemplate: `%s${TITLE_SEPARATOR}Spelvärde`,
  defaultTitle: title,
  description,
  dangerouslySetAllPagesToNoIndex: process.env.NEXT_PUBLIC_NO_ROBOTS === 'true',
  dangerouslySetAllPagesToNoFollow:
    process.env.NEXT_PUBLIC_NO_ROBOTS === 'true',
  openGraph: {
    type: 'website',
    locale: 'sv_SE',
    url: 'https://www.spelvarde.se',
    site_name: 'Spelvärde',
    title,
    description,
    images: [
      {
        url: 'https://media.trmedia.se/w_800,h_230,c_lpad,f_auto,fl_lossy,q_auto,b_rgb:292B2E,bo_200px_solid_rgb:292B2E,z_1.5/brands/logos/jkohfes0wfhveng4vgcc',
      },
    ],
  },
  /* TODO facebook: {
    appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  }, */
  twitter: {
    cardType: 'summary_large_image',
    handle: '@spelvarde_se',
  },
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'icon',
      href: '/favicon-32x32.png',
      type: 'image/png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/favicon-16x16.png',
      type: 'image/png',
      sizes: '16x16',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#292b2e',
    },
  ],
  additionalMetaTags: [
    /* TODO? { property: 'fb:pages', content: process.env.NEXT_PUBLIC_FB_PAGE_ID }, */
    /* {
    TODO?  name: 'facebook-domain-verification', 
      content: process.env.NEXT_PUBLIC_FB_VERIFICATION_CODE, // vx9mrs4qolytzoekeon8s3rhi403m8
    }, */
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
    { name: 'msapplication-TileColor', content: '#292b2e' },
    { name: 'theme-color', content: '#ffffff' },
  ],
};

export default defaultConfig;
