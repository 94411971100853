import { createApi } from '@reduxjs/toolkit/query/react';
import { Context } from 'next-redux-wrapper';

import { isFetchBaseQueryError } from '~/types/assert';
import { User } from '~/types/models/User';

import { createBaseQuery, PrepareHeadersFn } from './utils';

const prepareHeaders: PrepareHeadersFn = (headers, api) => {
  const ctx = api.extra as Context;

  // Pass on cookies in server to server requests so user
  // can be authenticated.
  if ('req' in ctx && ctx.req && ctx.req.headers.cookie) {
    headers.set('cookie', ctx.req.headers.cookie);
  }

  return headers;
};

export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: createBaseQuery('auth', prepareHeaders),
  endpoints: builder => ({
    validate: builder.query<User, void>({
      query: () => 'validate',
      async onQueryStarted(_, { queryFulfilled, getCacheEntry, dispatch }) {
        queryFulfilled.catch(error => {
          if (
            isFetchBaseQueryError(error.error) &&
            error.error.status === 401 &&
            getCacheEntry().data != null
          ) {
            dispatch(authApi.util.resetApiState());
          }
        });
      },
    }),
  }),
});

export const {
  useValidateQuery,
  endpoints: {
    validate: { useQueryState },
  },
} = authApi;
