import { createSelector, createSlice } from '@reduxjs/toolkit';
import { canUseDOM } from 'exenv';
import { HYDRATE } from 'next-redux-wrapper';

import type { AppState } from '~/main/store';

interface configState {
  origin?: string;
}

const initialState: configState = {
  origin: '',
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addDefaultCase((_, action) => {
      if (action.type === HYDRATE) {
        return action.payload.config;
      }
    });
  },
});

export const stateSelector = (state: AppState) => state.config;

export const selectOrigin = createSelector(stateSelector, state => {
  let origin = state.origin;
  if (origin === '' && canUseDOM) {
    origin = window.location.origin;
  }
  return origin;
});

export default slice;
