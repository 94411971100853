import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
//import { setupListeners } from '@reduxjs/toolkit/query';
import { Context, createWrapper } from 'next-redux-wrapper';

import { authApi } from '~/services/auth';
import { traisApi } from '~/services/trais';
import { vercelApi } from '~/services/vercel';

import baseUrlState from './config';

// Exported for Storybook
export const makeStore = (ctx: Context) => {
  let initialState;

  if ('req' in ctx && ctx.req != null) {
    initialState = {
      config: {
        origin: `${ctx.req.headers['x-forwarded-proto'] || 'http'}://${
          ctx.req.headers['x-forwarded-host'] || ctx.req.headers['host']
        }`,
      },
    };
  }

  const store = configureStore({
    reducer: {
      [authApi.reducerPath]: authApi.reducer,
      [traisApi.reducerPath]: traisApi.reducer,
      [vercelApi.reducerPath]: vercelApi.reducer,
      [baseUrlState.name]: baseUrlState.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          // https://github.com/reduxjs/redux-toolkit/issues/2228
          extraArgument: ctx,
        },
      })
        // Must use concat due to typescript
        // see https://github.com/reduxjs/redux-toolkit/issues/2089
        // eslint-disable-next-line unicorn/prefer-spread
        .concat(authApi.middleware, traisApi.middleware, vercelApi.middleware),
    preloadedState: initialState,
    devTools: true, // !isServer,
  });

  if (!('req' in ctx)) {
    // TODO - fetch client info (IP-address), probably for triggerbee only which we maybe will integrate?
    // store.dispatch(vercelApi.endpoints.getClientInfo.initiate());

    // Poll auth api twice per minute
    store.dispatch(
      authApi.endpoints.validate.initiate(undefined, {
        subscriptionOptions: { pollingInterval: 30000 },
      }),
    );
  }

  return store;
};

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
//setupListeners(store.dispatch);

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
export type AppDispatch = AppStore['dispatch'];

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
