import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import theme from '@trmediaab/theme-spelvarde';
import { ThemeProvider } from '@trmediaab/zebra';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { Provider } from 'react-redux';

import ErrorBoundary from '~/components/ErrorBoundary';
import GlobalStyles from '~/main/GlobalStyles';
import seoDefaults from '~/main/next-seo.config';
import { wrapper } from '~/main/store';

type NextPageWithLayout = NextPage & {
  getLayout?: (
    page: React.ReactElement,
    pageProps: AppProps['pageProps'],
  ) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function SpelvardeApp({
  Component,
  ...rest
}: AppPropsWithLayout) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <ErrorBoundary>
      {process.env.NEXT_PUBLIC_TAGMANAGER_ENABLED === 'true' && (
        <Script
          id="gtm-script"
          key="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
          }}
        />
      )}
      <Provider store={store}>
        <ThemeProvider
          theme={theme}
          disableVendorPrefixes={
            process.env.NEXT_PUBLIC_DISABLE_VENDOR_PREFIXES === 'true'
          }
        >
          <DefaultSeo {...seoDefaults} />
          <GlobalStyles />
          {getLayout(<Component {...props.pageProps} />, props.pageProps)}
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}
