import { createApi } from '@reduxjs/toolkit/query/react';
import { Context, HYDRATE } from 'next-redux-wrapper';

import type { AppState } from '~/main/store';

import { authApi } from '../auth';
import { createBaseQuery, PrepareHeadersFn } from '../utils';

const prepareHeaders: PrepareHeadersFn = (headers, api) => {
  const ctx = api.extra as Context;

  const { data: user } = authApi.endpoints.validate.select()(
    api.getState() as AppState,
  );

  // Site
  headers.set('site', 'spelvarde');

  // If the type is a mutation we need to set CSRF Token
  if (api.type === 'mutation' && user != null) {
    headers.set('X-JWT-CSRF-Token', user.csrf_token);
  }

  // Pass on cookies in server to server requests so user
  // can be authenticated.
  if ('req' in ctx && ctx.req && ctx.req.headers.cookie) {
    headers.set('cookie', ctx.req.headers.cookie);
  }

  return headers;
};

export const traisApi = createApi({
  reducerPath: 'trais',
  baseQuery: createBaseQuery('trais', prepareHeaders),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
});
/* 

export function useGetPersistentFileValueQueryWithReturnType<
  T extends TeaserListQueryArgs,
>(queryData: T): PersistentFileValue<T['itemKey']> | undefined {
  const { data } = useGetPersistentFileValueQuery(queryData);
  return data;
}
 */
